import { useState, useEffect, useRef } from "react";
import { AiOutlinePlus, AiOutlineUpload } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
  getResumeData,
  setEmptyForm,
  setButtonText,
} from "store/resumeReducer";
import FormCreateNew from "../form/FormCreateNew";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { importExelService } from "services/import.service";
import ProfileTable from "./ProfileTable";
import CustomModal from "components/CustomModal";
import PDFPreview from "components/PDFPreview";
import { toggleOpenPdf } from "store/resumeReducer";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const Dashboard = () => {
  const [isCreate, setIsCreate] = useState(false);
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const pdfRef = useRef(null);

  //table state
  const [globalFilter, setGlobalFilter] = useState("");
  const { resumeData, page, pageSize, totalCount, openPdf } = useSelector(
    (state) => state.resume
  );

  const dispatch = useDispatch();

  const toggleUpdate = () => {
    setIsCreate(true);
    dispatch(setButtonText("Tạo"));
    dispatch(setEmptyForm());
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    try {
      setUploading(true);
      await importExelService(file);
      dispatch(getResumeData());
    } catch (error) {
    } finally {
      setUploading(false);
      setShowModal(false);
    }
  };

  const clearFile = () => {
    setFile(null);
  };

  useEffect(() => {
    dispatch(getResumeData({ page, size: pageSize, name: globalFilter }));
  }, [dispatch, globalFilter, page, pageSize]);

  // toggle pdf
  const handleTogglePdfPreview = () => {
    dispatch(toggleOpenPdf());
  };
  const handleDownload = async () => {
    try {
      const input = pdfRef.current;

      let pdfjs = document.querySelector("#pdf-container");
      const canvas = await html2canvas(input);
      const imageData = canvas.toDataURL("image/png");
      // let pdf = new jsPDF("l", "mm", [1500, 1400]);
     const pdf = new jsPDF({
       orientation: "portrait",
       unit: "px",
       format: "a4",
     });
      // Adjust canvas height to match the height of the content
      const width = pdf.internal.pageSize.getWidth();
      const scaleFactor = width / canvas.width;
      const height = canvas.height * scaleFactor;

      pdf.addImage(imageData, "PNG", 0, 0, width, height);
      // pdf.html(pdfjs, {
      //   callback: function (doc) {
      //     // doc.setFont("Arial");
      //     doc.setFontSize(16);
      //     doc.save("soyeulilich.pdf");
      //   },
      //   x: 0,
      //   y: 0,
      // });
      pdf.setFontSize(16);
      pdf.save("soyeulilich.pdf");
    } catch (error) {
      console.log("error: ", error);
    }
  };
  return (
    <div>
      {/* Tables & Charts */}

      <div className="flex gap-3">
        {isCreate ? (
          <button
            className="my-5 flex items-center gap-1 rounded-md bg-brand-500 p-2 normal-case text-white dark:bg-white dark:text-navy-700"
            onClick={() => setIsCreate(!isCreate)}
          >
            Xem dữ liệu
          </button>
        ) : (
          <button
            className="my-5 flex items-center gap-1 rounded-md bg-brand-500 p-2 normal-case text-white dark:bg-white dark:text-navy-700"
            onClick={toggleUpdate}
          >
            <AiOutlinePlus /> Thêm mới
          </button>
        )}

        <button
          className="my-5 flex items-center gap-1 rounded-md border border-solid border-brand-500  p-2 normal-case text-brand-500 dark:bg-white dark:text-navy-700"
          onClick={() => setShowModal(true)}
        >
          <AiOutlineUpload /> Tải lên
        </button>
      </div>

      <div className="mt-5 grid grid-cols-1 gap-5">
        {/* Check Table */}
        <div>
          {isCreate ? (
            <FormCreateNew />
          ) : (
            <ProfileTable
              data={resumeData || []}
              toggleUpdate={toggleUpdate}
              page={page}
              pageSize={pageSize}
              totalCount={totalCount}
              setGlobalFilter={setGlobalFilter}
              globalFilter={globalFilter}
            />
          )}
        </div>
      </div>

      {/* modal pdf */}
      {/* {openPdf && <PDFPreview handleClose={handleTogglePdfPreview} />} */}
      <CustomModal
        children={
          <PDFPreview handleClose={handleTogglePdfPreview} pdfRef={pdfRef} />
        }
        open={openPdf}
        handleClose={handleTogglePdfPreview}
        handleDownload={handleDownload}
      />
      {/* import */}
      <Dialog
        open={showModal}
        onClose={() => {
          setShowModal(false);
          clearFile();
        }}
      >
        <DialogTitle className="text-xl font-bold">Tải file Excel</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setShowModal(false);
            clearFile();
          }}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <Close />
        </IconButton>
        <DialogContent>
          <label
            htmlFor="upload-file"
            className="flex h-32 w-full cursor-pointer items-center justify-center rounded-md bg-gray-100 hover:bg-gray-200"
            onDrop={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setFile(e.dataTransfer.files[0]);
            }}
            onDragOver={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <input
              type="file"
              id="upload-file"
              accept=".xlsx, .xls"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            {file ? (
              <p className="text-gray-500">{file.name}</p>
            ) : (
              <p className="text-gray-500">Kéo và thả file hoặc chọn file</p>
            )}
          </label>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleUpload}
            disabled={!file || uploading}
            variant="contained"
            color="primary"
          >
            {uploading ? "Đang tải lên..." : "Tải lên"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Dashboard;
