import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isSidebarOpen: false,
  isToastMessageShow: false,
  toast: {
    message: "",
    severity: "success",
  },
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setSidebarOpen: (state) => {
      state.isSidebarOpen = !state.isSidebarOpen;
    },
    setIsToastMessageShow: (state) => {
      state.isToastMessageShow = !state.isToastMessageShow;
    },
    setMessage: (state, action) => {
      const { message, severity } = action.payload;
      state.toast.message = message;
      state.toast.severity = severity;
    },
  },
});

export const {
  setMode,
  setLayout,
  setSidebarOpen,
  setIsToastMessageShow,
  setMessage,
  setMeta,
} = globalSlice.actions;

export default globalSlice.reducer;
