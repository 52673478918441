import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const Circular = () => {
  return (
    <CircularProgress
      sx={{ width: "20px !important", height: "20px !important" }}
    />
  );
};

export default Circular;
