/* eslint-disable no-unused-expressions */
import { login } from "services/user.service";
import { removeFromLocalStorage, setLocalStorage } from "utils/local-storage";
import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { getFromLocalStorage } from "utils/local-storage";
import { AUTHENTICATION } from "constants/AuthConstant";

export const signIn = createAsyncThunk("user/signIn", async (user) => {
  try {
    const response = await login(user);
    console.log('response: ', response);
    if (response.status === 200) {
      setLocalStorage(AUTHENTICATION, response.data);

    }
    return response;
  } catch (error) {
    return false;
  }
});


export const signOut = createAsyncThunk("user/signOut", async (user) => {
  return { callBack: user.onSignOut };
});

export const getUserData = createAsyncThunk(
  "user/getUserData",
  async (userData) => {
    return userData;
  }
);

export const usersAdapter = createEntityAdapter();

const initialState = usersAdapter.getInitialState({
  isAuth: !!getFromLocalStorage(AUTHENTICATION),
  user: null,
  loading: false,
  infoVerifyRequest: null,
});

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loginUser: (state, action) => {
      state.user = action.payload;
      state.isAuth = true;
      setLocalStorage(AUTHENTICATION, action.payload);
    },
    logoutUser: (state, action) => { 
      state.user = null;
      state.isAuth = false;
      setLocalStorage(AUTHENTICATION, null);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.fulfilled, (state, action) => {
        state.loading = false;
        const infoUser = action.payload.response;
        if (infoUser.status === 200) {
          state.isAuth = !!action.payload.responseUser?.data;
          state.user = action.payload.responseUser?.data;
          setLocalStorage(AUTHENTICATION, infoUser.data);
        }
        action.payload.callBack
          ? action.payload.callBack(action.payload.response)
          : null;
      })
      .addCase(signOut.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuth = false;
        state.user = null;
        removeFromLocalStorage(AUTHENTICATION);
        action.payload.callBack ? action.payload.callBack() : null;
      })
      .addCase(getUserData.fulfilled, (state) => {
        const userData = getFromLocalStorage(AUTHENTICATION);
        state.userData = userData;
      });
  },
});
export const { loginUser, logoutUser } = userSlice.actions;

const userReducer = userSlice.reducer;

// Selector to check if user is authenticated
export const selectIsAuthenticated = (state) => state.user.isAuth;

export default userReducer;
