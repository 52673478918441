import * as yup from "yup";
import { passwordRegExp, regexEmail } from "../helpers";

export const SignUpSchema = () => {
  return yup.object().shape({
    email: yup
      .string()
      .nullable()
      .trim()
      .required("Bạn phải nhập Email")
      .email("Email không hợp lệ")
      .matches(regexEmail, "Email không hợp lệ")
      .max(200, "Email không hợp lệ"),
    name: yup
      .string()
      .nullable()
      .trim()
      .required("Bạn phải nhập tên"),
    password: yup
      .string()
      .nullable()
      .required("Bạn phải nhập Password")
      .min(8, "Password phải dài hơn 8 kí tự")
      .max(64, "Password phải ngắn hơn 64 kí tự")
      .matches(passwordRegExp, "Password phải bắt đầu bằng chữ IN HOA"),
    repeatPassword: yup
      .string()
      .nullable()
      .oneOf([yup.ref("password"), null], "Password nhập lại không khớp"),
  });
};

export const LoginSchema = () => {
  return yup.object().shape({
    email: yup
      .string()
      .nullable()
      .trim()
      .required("Bạn phải nhập Email")
      .email("Email không hợp lệ")
      .matches(regexEmail, "Email không hợp lệ")
      .max(200, "Email không hợp lệ"),
    password: yup
      .string()
      .nullable()
      .required("Bạn phải nhập Mật khẩu")
      .min(8, "Mật khẩu phải dài hơn 8 kí tự")
      .max(64, "Mật khẩu phải ngắn hơn 64 kí tự")
      .matches(passwordRegExp, "Mật khẩu phải bắt đầu bằng chữ IN HOA"),
  });
};

export const ContactSchema = () => {
  return yup.object().shape({
    fullName: yup
      .string()
      .nullable()
      .trim()
      .required("Bạn phải nhập Họ và tên"),
    email: yup
      .string()
      .nullable()
      .trim()
      .required("Bạn phải nhập Email")
      .email("Email không hợp lệ")
      .matches(regexEmail, "Email không hợp lệ")
      .max(200, "Email không hợp lệ"),
    phoneNumber: yup
      .string()
      .nullable()
      .required("Bạn phải nhập số điện thoại")
      .min(10, "Số phải có 10 chữ số")
      .max(14, "Số điện thoại không đúng định dạng"),
    company: yup
      .string()
      .nullable()
      .trim()
      .required("Bạn phải nhập Tên công ty"),
    purpose: yup
      .string()
      .nullable()
      .trim()
      .required("Bạn phải nhập Mục đích liên hệ"),
  });
};



export const ChangePasswordSchema = () => {
  return yup.object().shape({
    oldPassword: yup
      .string()
      .nullable()
      .required("Bạn phải nhập Password")
      .min(8, "Password phải dài hơn 8 kí tự")
      .max(64, "Password phải ngắn hơn 64 kí tự")
      .matches(passwordRegExp, "Password phải bắt đầu bằng chữ IN HOA"),
    newPassword: yup
      .string()
      .nullable()
      .required("Bạn phải nhập Password")
      .min(8, "Password phải dài hơn 8 kí tự")
      .max(64, "Password phải ngắn hơn 64 kí tự")
      .matches(passwordRegExp, "Password phải bắt đầu bằng chữ IN HOA"),
    retypePassword: yup
      .string()
      .oneOf([yup.ref("newPassword"), null], "Password nhập lại không khớp"),
  });
};


export const CreateNewResumeForm = () => {
  return yup.object().shape({
    phapDanh: yup.string().nullable().trim().required("Bạn phải nhập pháp danh"),
    hoTenKhaiSinh: yup.string().nullable().trim().required("Bạn phải nhập họ tên"),
    ngaySinh: yup.string().nullable().trim().required("Bạn phải nhập ngày sinh"),
    noiSinh: yup.string().nullable().trim().required("Bạn phải nhập nơi sinh"),
    soCMND: yup.string().nullable().trim().required("Bạn phải nhập số CCCD"),
    ngayCapCMND: yup.string().nullable().trim().required("Bạn phải nhập ngày cấp CMND"),
    noiCapCMND: yup.string().nullable().trim().required("Bạn phải nhập nơi cấp CMND"),
    soChungNhanTangNi: yup.string().nullable().trim().required("Bạn phải nhập số chứng nhận tăng ni"),
    ngayCapCNTN: yup.string().nullable().trim().required("Bạn phải nhập ngày cấp chứng nhận tăng ni"),
    hoKhauThuongTru: yup.string().nullable().trim().required("Bạn phải nhập hộ khẩu thường trú"),
  });
};