// App.js
import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import "./index.css";

import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import { selectIsAuthenticated } from "store/userReducer";

const ProtectedRoute = ({ element, ...rest }) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);

  return isAuthenticated ? (
    React.cloneElement(element, rest)
  ) : (
    <Navigate to="/auth/sign-in" replace />
  );
};

const App = () => {
  return (
    <Routes>
      <Route path="auth/*" element={<AuthLayout />} />
      <Route
        path="admin/*"
        element={<ProtectedRoute element={<AdminLayout />} />}
      />
      <Route path="/" element={<Navigate to="/admin" replace />} />
    </Routes>
  );
};

export default App;
