import { fetchClient } from "api/app.service";
import { HOST, UPLOAD_IMG } from "constants/Api";

export const uploadMedia = async (hasToken, formFile) => {
  var formData = new FormData();
  formData.append("avatar", formFile);
  return fetchClient(hasToken, true)
    .post(`${HOST}${UPLOAD_IMG}`, formData)
    .then((res) => {
      if (res.status === 200) {
        return res.data
       
     }
    })
    .catch((error) => {
      throw error;
    });
};

// export const uploadVideoMedia = async (hasToken, formFile) => {
//   var formData = new FormData();
//   formData.append("file", formFile);
//   return fetchClient(hasToken, true)
//     .post(UPLOAD_IMG, formData)
//     .then((res) => {
//       let urlFile = "";
//       if (res?.data && res?.data.length > 0) {
//         urlFile = res?.data[0].url;
//       }
//       return urlFile;
//     })
//     .catch((error) => {
//       throw error;
//     });
// };
