/* eslint-disable react/prop-types */
import Box from "@mui/material/Box";

import Modal from "@mui/material/Modal";
import {  IconButton } from "@mui/material";
import { Close, FileDownload } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // height: "600px",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "6px",
};

export default function CustomModal({
  open,
  handleClose,
  children,
  height = "800px",
  handleDownload,
}) {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="w-[80%]">
          <Box className="absolute right-[-10px] top-[-15px] mb-4">
            <IconButton
              sx={{ border: "1px solid red", bgcolor: "white" }}
              onClick={handleClose}
            >
              <Close sx={{ fontSize: "1rem", color: "red" }} />
            </IconButton>
          </Box>
          {handleDownload && (
            <IconButton
              className="fixed right-0 bottom-0"
              onClick={handleDownload}
            >
              <FileDownload sx={{ fontSize: "2rem", color: "#422BFC" }} />
            </IconButton>
          )}
          <Box
            className="w-full overflow-y-auto  overflow-x-hidden p-5"
            style={{ height: height }}
          >
            <Box className="mt-8">{children}</Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
