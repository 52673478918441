import Card from "components/card";
import InputField from "components/fields/InputField";
import TextField from "components/fields/TextField";
import React, { useState } from "react";
import FamilyInfoTable from "./components/FamilyInfoTable";
import InputUpload from "components/fields/InputUpload";
import { useDispatch, useSelector } from "react-redux";
import { setIsToastMessageShow } from "store/globalReducer";
import { setMessage } from "store/globalReducer";
import { useForm } from "react-hook-form";
import styles from "./index.module.css";
import BangGioiPham from "./components/BangGioiPham";
import { yupResolver as FormResolver } from "@hookform/resolvers/yup";
import { CreateNewResumeForm } from "utils/validate/schema";
import { uploadMedia } from "services/upload.service";
import { setCreateNewResume } from "store/resumeReducer";
import { createNewResume } from "services/resume.service";

const FormCreateNew = () => {
  const dispatch = useDispatch();

  // const [avatarUrl, setAvatarUrl] = useState(null);
  const inputCs = "border border-solid border-brand-500 z-50";
  const {
    resumeForm,
    quanHeGiaDinh,
    ngayThangNamXuatGiaThoGioiTrongHePhai,
    buttonText,
  } = useSelector((state) => state.resume);

  const [avatar, setAvatar] = useState(resumeForm?.avatar || null);
  const [file, setFile] = useState(null);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: resumeForm,
    mode: "onBlur",
    resolver: FormResolver(CreateNewResumeForm()),
  });
  const [families, setFamilies] = useState(quanHeGiaDinh || []);
  const [gioiPham, setGioiPham] = useState(
    ngayThangNamXuatGiaThoGioiTrongHePhai || []
  );

  const handleSetAvatar = async (event) => {
    let file = event.target.files[0];
    setFile(file);
    const preview = URL.createObjectURL(file);
    if (!file) {
      dispatch(setIsToastMessageShow());
      dispatch(
        setMessage({
          message: "Bạn chưa tải ảnh",
          severity: "info",
        })
      );
    }
    setAvatar(preview);
    // const response = await uploadMedia(false, file);
    // console.log("response: ", response.url);

    // setAvatarUrl(response.url);
    event.target.value = "";
  };

  const onSubmit = async (data) => {
    try {
      let submitData = data;
      submitData.quanHeGiaDinh = families;
      submitData.ngayThangNamXuatGiaThoGioiTrongHePhai = gioiPham;
      if (file != null) {
        let image = await uploadMedia(false, file);
        submitData.avatar = image.url;
      } else {
        submitData.avatar = null;
      }

      const response = await createNewResume(submitData);

      dispatch(setCreateNewResume(response?.data));

      if (buttonText === "Cập nhật") {
        dispatch(
          setMessage({
            message: "Cập nhật thành thành công",
            severity: "success",
          })
        );
      } else {
        // reset form
        reset();
        setFamilies([]);
        setGioiPham([]);
        dispatch(
          setMessage({
            message: "Tạo thành thành công",
            severity: "success",
          })
        );
      }
      dispatch(setIsToastMessageShow());
    } catch (error) {
      const msg = error?.response?.data?.message;
      dispatch(setIsToastMessageShow());
      dispatch(
        setMessage({
          message: msg,
          severity: "error",
        })
      );
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.container_overlay}></div>
      <Card extra={styles.card}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <header className="relative flex items-center justify-between pt-4">
            <div className="flex w-full items-center justify-between">
              <h3 className="text-xl font-bold text-navy-700 dark:text-white">
                Nhập thông tin
              </h3>
              <button
                type="submit"
                className="rounded-md bg-brand-500 px-5 py-2 normal-case text-white dark:bg-white dark:text-navy-700"
              >
                {buttonText}
              </button>
            </div>
          </header>
          <div className="my-8 overflow-x-hidden">
            <div className="w-[100px]">
              <InputUpload value={avatar} handleChange={handleSetAvatar} />
            </div>

            <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
              <InputField
                variant="auth"
                extra="mb-3"
                label="Pháp danh *"
                id="phapDanh"
                type="text"
                inputCS={inputCs}
                name="phapDanh"
                register={register("phapDanh")}
                helperText={errors.phapDanh ? errors.phapDanh.message : ""}
              />
              <InputField
                variant="auth"
                extra="mb-3"
                label="Họ và tên khai sinh *"
                id="hoTenKhaiSinh"
                type="text"
                inputCS={inputCs}
                name="hoTenKhaiSinh"
                register={register("hoTenKhaiSinh")}
                helperText={
                  errors.hoTenKhaiSinh ? errors.hoTenKhaiSinh.message : ""
                }
              />
              <InputField
                variant="auth"
                extra="mb-3"
                label="Ngày tháng năm sinh *"
                id="ngaySinh"
                type="date"
                inputCS={inputCs}
                name="ngaySinh"
                register={register("ngaySinh")}
                helperText={errors.ngaySinh ? errors.ngaySinh.message : ""}
              />
              <InputField
                variant="auth"
                extra="mb-3"
                label="Nơi sinh *"
                id="noiSinh"
                type="text"
                inputCS={inputCs}
                name="noiSinh"
                register={register("noiSinh")}
                helperText={errors.noiSinh ? errors.noiSinh.message : ""}
              />
            </div>
            <div className="grid grid-cols-1 gap-3 md:grid-cols-3">
              <InputField
                variant="auth"
                extra="mb-3"
                label="Giấy CMND/CCCD số *"
                id="soCMND"
                type="text"
                inputCS={inputCs}
                name="soCMND"
                register={register("soCMND")}
                helperText={errors.soCMND ? errors.soCMND.message : ""}
              />
              <InputField
                variant="auth"
                extra="mb-3"
                label="Cấp ngày *"
                id="ngayCapCMND"
                type="date"
                inputCS={inputCs}
                name="ngayCapCMND"
                register={register("ngayCapCMND")}
                helperText={
                  errors.ngayCapCMND ? errors.ngayCapCMND.message : ""
                }
              />
              <InputField
                variant="auth"
                extra="mb-3"
                label="Nơi cấp *"
                id="noiCapCMND"
                type="text"
                inputCS={inputCs}
                name="noiCapCMND"
                register={register("noiCapCMND")}
                helperText={errors.noiCapCMND ? errors.noiCapCMND.message : ""}
              />
            </div>

            <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
              <InputField
                variant="auth"
                extra="mb-3"
                label="Giấy chứng nhận Tăng Ni số *"
                id="soChungNhanTangNi"
                type="text"
                inputCS={inputCs}
                name="soChungNhanTangNi"
                register={register("soChungNhanTangNi")}
                helperText={
                  errors.soChungNhanTangNi
                    ? errors.soChungNhanTangNi.message
                    : ""
                }
              />

              <InputField
                variant="auth"
                extra="mb-3"
                label="Ngày cấp *"
                id="ngayCapCNTN"
                type="date"
                inputCS={inputCs}
                name="ngayCapCNTN"
                register={register("ngayCapCNTN")}
                helperText={
                  errors.ngayCapCNTN ? errors.ngayCapCNTN.message : ""
                }
              />
              <InputField
                variant="auth"
                extra="mb-3"
                label="Quê quán"
                id="queQuan"
                inputCS={inputCs}
                name="queQuan"
                register={register("queQuan")}
              />
              <InputField
                variant="auth"
                extra="mb-3"
                label="Hộ khẩu thường trú *"
                id="hoKhauThuongTru"
                type="text"
                inputCS={inputCs}
                name="hoKhauThuongTru"
                register={register("hoKhauThuongTru")}
                helperText={
                  errors.hoKhauThuongTru ? errors.hoKhauThuongTru.message : ""
                }
              />
              <InputField
                variant="auth"
                extra="mb-3"
                label="Hiện ở Tịnh xá (Ghi rõ địa chỉ)"
                id="hienO"
                type="text"
                inputCS={inputCs}
                name="hienO"
                register={register("hienO")}
              />
              <InputField
                variant="auth"
                extra="mb-3"
                label="Địa chỉ tạm trú"
                id="tamTruTai"
                type="text"
                inputCS={inputCs}
                name="tamTruTai"
                register={register("tamTruTai")}
              />
              <InputField
                variant="auth"
                extra="mb-3"
                label="Thời gian xuất gia vào Tịnh xá (ghi rõ địa chỉ TX)"
                id="ngayXuatGiaVaoTinhXa"
                type="text"
                inputCS={inputCs}
                name="ngayXuatGiaVaoTinhXa"
                register={register("ngayXuatGiaVaoTinhXa")}
              />
              <InputField
                variant="auth"
                extra="mb-3"
                label="Thời gian và nơi xuống tóc xuất gia (ghi rõ địa chỉ TX)"
                id="ngayXuatGiaXuongToc"
                type="text"
                inputCS={inputCs}
                name="ngayXuatGiaXuongToc"
                register={register("ngayXuatGiaXuongToc")}
              />

              <InputField
                variant="auth"
                extra="mb-3"
                label="Bổn sư và địa chỉ bổn sư"
                id="bonSu"
                type="text"
                inputCS={inputCs}
                name="bonSu"
                register={register("bonSu")}
              />
            </div>
            <h2 className="my-2 text-lg font-medium underline">Trình độ</h2>
            <div className="grid grid-cols-1 gap-3 md:grid-cols-4">
              <InputField
                variant="auth"
                extra="mb-3"
                label="Văn hoá"
                id="vanHoa"
                type="text"
                inputCS={inputCs}
                name="trinhDo.vanHoa"
                register={register("trinhDo.vanHoa")}
              />
              <InputField
                variant="auth"
                extra="mb-3"
                label="Phật học"
                id="phatHoc"
                type="text"
                inputCS={inputCs}
                name="trinhDo.phatHoc"
                register={register("trinhDo.phatHoc")}
              />
              <InputField
                variant="auth"
                extra="mb-3"
                label="Ngoại ngữ"
                id="ngoaiNgu"
                type="text"
                inputCS={inputCs}
                name="trinhDo.ngoaiNgu"
                register={register("trinhDo.ngoaiNgu")}
              />
              <InputField
                variant="auth"
                extra="mb-3"
                label="Nghiệp vụ chuyên môn"
                id="ngheNghiepChuyenMon"
                type="text"
                inputCS={inputCs}
                name="trinhDo.ngheNghiepChuyenMon"
                register={register("trinhDo.ngheNghiepChuyenMon")}
              />
            </div>
            <div className="grid grid-cols-1 gap-3">
              <TextField
                variant="auth"
                extra="mb-3"
                label="Tiểu sử"
                id="tieuSu"
                type="text"
                rows={5}
                inputCS={inputCs}
                name="tieuSu"
                register={register("tieuSu")}
              />
            </div>

            <div className="grid grid-cols-1 gap-3 md:grid-cols-3">
              <InputField
                variant="auth"
                extra="mb-3"
                label="Tham gia các sinh hoạt xã hội"
                id="thamGiaSinhHoatXaHoi"
                type="text"
                inputCS={inputCs}
                name="thamGiaSinhHoatXaHoi"
                register={register("thamGiaSinhHoatXaHoi")}
              />

              <InputField
                variant="auth"
                extra="mb-3"
                label="Tham gia các phong trào, đoàn thể, đảng phải"
                id="thamGiaPhongTrao"
                type="text"
                inputCS={inputCs}
                name="thamGiaPhongTrao"
                register={register("thamGiaPhongTrao")}
              />
              <InputField
                variant="auth"
                extra="mb-3"
                label="Khen thưởng, kỷ luật"
                id="khenThuongKyLuat"
                inputCS={inputCs}
                name="khenThuongKyLuat"
                register={register("khenThuongKyLuat")}
              />
            </div>

            <div className="z-20">
              <h2 className="my-2 text-lg font-medium underline">
                Ngày tháng năm xuất gia Thọ giới trong Hệ phái
              </h2>
              <BangGioiPham rows={gioiPham} setRows={setGioiPham} />
            </div>

            <div className="z-20">
              <h2 className="my-2 text-lg font-medium underline">
                Quan hệ gia đình
              </h2>
              <FamilyInfoTable rows={families} setRows={setFamilies} />
            </div>
          </div>
          <div className="mb-5 flex justify-end">
            <button
              type="submit"
              className="rounded-md bg-brand-500 px-5 py-2 normal-case text-white dark:bg-white dark:text-navy-700"
            >
              {buttonText}
            </button>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default FormCreateNew;
