import { fetchClient } from "api/app.service";
import { HOST } from "constants/Api";
import { IMPORT_EXCEL } from "constants/Api";

export const importExelService = async (formFile) => {
  try {
    var formData = new FormData();
    formData.append("profile", formFile);
    const response = await fetchClient(true, true).post(`${HOST}${IMPORT_EXCEL}`, formData);
    return response.data;
  } catch (error) {
    throw error;
  }
};
