import { fetchClient } from "api/app.service";
import { HOST, LOGIN, REGISTER } from "constants/Api";
import axios from "axios";
import { INFO_USER } from "constants/Api";


export const register = async (data) => {
  return axios
    .post(`${HOST}${REGISTER}`, data)
    .then((res) => {
      console.log('res: ', res);
      return res;
    })
    .catch((error) => {
      console.log('error: ', error);
      throw error;  
    });
};

export const login = async (data) => {
  return axios
    .post(`${HOST}${LOGIN}`, data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;  
    });
};

export const getInfoUser = async (params) => {
  // Use fetchClient() when call api have token
  return fetchClient(true)
    .get(`${HOST}${INFO_USER}`, { params: params })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;  
    });
};

const handleError = (error) => {
  if (error.response) {
    return error.response.data;
  } else {
    return error.message;
  }
};
