import { fetchClient } from "api/app.service";
import { HOST } from "constants/Api";
import axios from "axios";
import { CREATE_RESUME } from "constants/Api";
import { GET_RESUME } from "constants/Api";
import { DELETE_RESUME } from "constants/Api";


export const createNewResume = async (data) => {
  return fetchClient(true)
    .post(`${HOST}${CREATE_RESUME}`, data)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
};

export const getAllResumeData = async (params) => {
  // Use fetchClient() when call api have token
  return fetchClient(true)
    .get(`${HOST}${GET_RESUME}`, { params: params })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteResumeData = async (id) => {
  // Use fetchClient() when call api have token
  return fetchClient(true)
    .delete(`${HOST}${DELETE_RESUME}${id}`)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      throw error;
    });
};

const handleError = (error) => {
  if (error.response) {
    return error.response.data;
  } else {
    return error.message;
  }
};
