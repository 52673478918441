/* eslint-disable react/jsx-pascal-case */
import {
  MRT_EditActionButtons,
  MantineReactTable,
  // createRow,
  useMantineReactTable,
} from "mantine-react-table";
import { ActionIcon, Flex, Stack, Text, Title, Tooltip } from "@mantine/core";
import { ModalsProvider, modals } from "@mantine/modals";
import { IconEdit, IconTrash } from "@tabler/icons-react";

import { useDispatch } from "react-redux";
import { deleteProfileById } from "store/resumeReducer";
import { Avatar } from "@mui/material";
import { changeResumeForm } from "store/resumeReducer";
import { setButtonText } from "store/resumeReducer";
import { setPage } from "store/resumeReducer";
import { useEffect, useState } from "react";
import { setPagesize } from "store/resumeReducer";
import { Visibility } from "@mui/icons-material";
import { toggleOpenPdf } from "store/resumeReducer";

const ProfileTable = (props) => {
  const {
    data,
    toggleUpdate,
    page,
    pageSize,
    totalCount,
    setGlobalFilter,
    globalFilter,
  } = props;

  const dispatch = useDispatch();
  const [pagination, setPagination] = useState({
    pageIndex: page,
    pageSize: pageSize, //customize the default page size
  });

  useEffect(() => {
    dispatch(setPage(pagination.pageIndex));
    dispatch(setPagesize(pagination.pageSize));
    //do something when the pagination state changes
  }, [dispatch, pagination.pageIndex, pagination.pageSize]);

  const columns = [
    {
      accessorKey: "avatar",
      header: "Hình",
      Cell: ({ cell }) => (
        <Avatar src={cell.getValue()} sx={{ width: 30, height: 30 }} />
      ),
    },
    {
      accessorKey: "hoTenKhaiSinh",
      header: "Họ & tên",
    },
    {
      accessorKey: "ngaySinh",
      header: "Ngày sinh",
    },
    {
      accessorKey: "phapDanh",
      header: "Pháp danh",
    },
    {
      accessorKey: "hoKhauThuongTru",
      header: "Thường trú",
    },
    {
      accessorKey: "hienO",
      header: "Hiện ở",
    },
    {
      accessorKey: "ngayXuatGiaVaoTinhXa",
      header: "Ngày xuất gia",
    },
    {
      accessorKey: "bonSu",
      header: "Bổn sư",
    },
    {
      accessorKey: "createdAt",
      header: "Ngày tạo",
      Cell: ({ cell }) => (
        <span>{new Date(cell.getValue()).toLocaleDateString()}</span>
      ),
    },
  ];
  // preview
  const handlePreview = (id) => {
    const update = data.find((item) => item._id === id);
    dispatch(changeResumeForm(update));
    dispatch(toggleOpenPdf())
  };
  // edit
  const handleEdit = (id) => {
    const update = data.find((item) => item._id === id);
    toggleUpdate();
    dispatch(changeResumeForm(update));
    dispatch(setButtonText("Cập nhật"));
  };
  //DELETE action ok
  const openDeleteConfirmModal = (row) =>
    modals.openConfirmModal({
      title: "Xoá người dùng",
      children: (
        <Text>
          Bạn có muốn xoá {row.original.hoTenKhaiSinh} . Hành động này sẽ không
          thể khôi phục
        </Text>
      ),
      labels: { confirm: "Delete", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onConfirm: () => dispatch(deleteProfileById({ id: row.original._id })),
    });

  const table = useMantineReactTable({
    columns,
    data: data || [], // pass data here
    createDisplayMode: "modal", //default ('row', and 'custom' are also available)
    editDisplayMode: "modal", //default ('row', 'cell', 'table', and 'custom' are also available)
    manualPagination: true,
    manualFiltering: true,
    rowCount: totalCount,
    onPaginationChange: setPagination, //hoist pagination state to your state when it changes internally
    state: { pagination, globalFilter }, //pass the pagination state to the table
    onGlobalFilterChange: setGlobalFilter,
    enableEditing: true,
    getRowId: (row) => row.id,
    mantineToolbarAlertBannerProps: false
      ? {
          color: "red",
          children: "Error loading data",
        }
      : undefined,
    mantineTableContainerProps: {
      sx: {
        minHeight: "500px",
      },
    },

    renderCreateRowModalContent: ({ table, row, internalEditComponents }) => (
      <Stack>
        <Title order={3}>Tạo mới</Title>
        {internalEditComponents}
        <Flex justify="flex-end" mt="xl">
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </Flex>
      </Stack>
    ),
    renderEditRowModalContent: ({ table, row, internalEditComponents }) => (
      <Stack>
        <Title order={3}>Cập nhật</Title>
        {internalEditComponents}
        <Flex justify="flex-end" mt="xl">
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </Flex>
      </Stack>
    ),
    renderRowActions: ({ row, table }) => (
      <Flex gap="md">
        <Tooltip label="Xem">
          <ActionIcon onClick={() => handlePreview(row.original._id)}>
            <Visibility />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Sửa">
          <ActionIcon onClick={() => handleEdit(row.original._id)}>
            <IconEdit />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Xoá">
          <ActionIcon color="red" onClick={() => openDeleteConfirmModal(row)}>
            <IconTrash />
          </ActionIcon>
        </Tooltip>
      </Flex>
    ),
  });

  return (
    <ModalsProvider>
      <MantineReactTable table={table} />
    </ModalsProvider>
  );
};

// const ProfileTable = (props) => {

//   return (
//     <ModalsProvider>
//       <AdvanceTable
//         data={data}
//         toggleUpdate={toggleUpdate}
//         page={page}
//         pageSize={pageSize}
//         totalCount={totalCount}
//       />
//     </ModalsProvider>
//   );
// };

export default ProfileTable;
