import InputField from "components/fields/InputField";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver as FormResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import { SignUpSchema } from "utils";
import { register as registerUser } from "services/user.service";
import { setIsToastMessageShow, setMessage } from "store/globalReducer";
import Circular from "components/progress/CircularProcess";
import { useState } from "react";

export default function SignUp() {
  const [submit, setSubmit] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      password: "",
      repeatPassword: "",
    },
    mode: "onBlur",
    resolver: FormResolver(SignUpSchema()),
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onSubmit = async (data) => {
    try {
      setSubmit(true);
      await registerUser(data);

      dispatch(setIsToastMessageShow());
      dispatch(
        setMessage({
          message: "Đăng ký thành công, vui lòng đăng nhập",
          severity: "success",
        })
      );
      setSubmit(false);
      navigate("/auth/sign-in");
    } catch (error) {
      setSubmit(false);
      const msg = error?.response?.data?.message;
      dispatch(setIsToastMessageShow());
      dispatch(
        setMessage({
          message: msg,
          severity: "error",
        })
      );
    }
  };

  return (
    <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign in section */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
          <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
            Đăng ký
          </h4>
          <p className="mb-9 ml-1 text-base text-gray-600">
            Nhập thông tin bên dưới để đăng ký
          </p>
          {/* Email */}
          <InputField
            variant="auth"
            extra="mb-3"
            label="Tên*"
            placeholder="Họ tên "
            id="name"
            name="name"
            register={register("name")}
            helperText={errors.name ? errors.name.message : ""}
          />

          {/* Email */}
          <InputField
            variant="auth"
            extra="mb-3"
            label="Email*"
            placeholder="mail@gmail.com"
            id="email"
            type="email"
            name="email"
            register={register("email")}
            helperText={errors.email ? errors.email.message : ""}
          />

          {/* Password */}
          <InputField
            variant="auth"
            extra="mb-3"
            label="Mật khẩu*"
            placeholder="Tối thiểu 8 ký tự"
            id="password"
            type="password"
            name="password"
            register={register("password")}
            helperText={errors.password ? errors.password.message : ""}
          />

          {/* Password */}
          <InputField
            variant="auth"
            extra="mb-3"
            label="Mật khẩu*"
            placeholder="Tối thiểu 8 ký tự"
            id="repeatPassword"
            type="password"
            name="repeatPassword"
            register={register("repeatPassword")}
            helperText={
              errors.repeatPassword ? errors.repeatPassword.message : ""
            }
          />

          <button
            type="submit"
            className="linear mt-2 flex  w-full items-center justify-center gap-2 rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            disabled={submit}
          >
            Đăng ký {submit && <Circular />}
          </button>
          <div className="mt-4">
            <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
              Đã có tài khoản?
            </span>
            <Link
              to="/auth/sign-in"
              className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
            >
              Đăng nhập
            </Link>
          </div>
        </div>
      </form>
    </div>
  );
}
