/* eslint-disable no-unused-expressions */
import {
    createAsyncThunk,
    createSlice,
    createEntityAdapter,
} from "@reduxjs/toolkit";
import { deleteResumeData } from "services/resume.service";
import { getAllResumeData } from "services/resume.service";



export const getResumeData = createAsyncThunk("resume/getAll", async (params) => {
    try {
        const response = await getAllResumeData(params);

        return response.data;
    } catch (error) {
        return false;
    }
});
// delete course by id
export const deleteProfileById = createAsyncThunk("resume/delete", async ({ id }) => {
    try {
        const response = await deleteResumeData(id);
        console.log('response: ', response);
        if (response.status === 200) {
            return { id }

        }

    } catch (error) {
        return false;
    }
});

const formInitialState = {
    phapDanh: "",
    hoTenKhaiSinh: "",
    ngaySinh: "",
    noiSinh: "",
    soCMND: "",
    ngayCapCMND: "",
    noiCapCMND: "",
    soChungNhanTangNi: "",
    ngayCapCNTN: "",
    queQuan: "",
    hoKhauThuongTru: "",
    hienO: "",
    tamTruTai: "",
    ngayXuatGiaVaoTinhXa: "",
    ngayXuatGiaXuongToc: "",
    bonSu: "",
    trinhDo: {
        vanHoa: "",
        phatHoc: "",
        ngoaiNgu: "",
        ngheNghiepChuyenMon: "",
    },
    tieuSuBanThan: "",
    khenThuongKyLuat: "",
    thamGiaPhongTrao: "",
    thamGiaSinhHoatXaHoi: "",
};
export const resumeAdapter = createEntityAdapter();

const initialState = resumeAdapter.getInitialState({
    loading: false,
    resumeData: [],
    page: 1,
    pageSize: 20,
    totalCount: 0,
    buttonText: 'Tạo',
    resumeForm: formInitialState,
    quanHeGiaDinh: [],
    ngayThangNamXuatGiaThoGioiTrongHePhai: [],
    openPdf: false

});

const resumeSlice = createSlice({
    name: "resume",
    initialState,
    reducers: {
        setCreateNewResume: (state, action) => {
            let index = state.resumeData.findIndex(item => item._id === action.payload?._id)
            if (index !== -1) {
                state.resumeData[index] = action.payload
            } else {
                state.resumeData.push(action.payload);
                
            }
        },
        setPage: (state, action) => {
            state.page = action.payload
        },
        setPagesize: (state, action) => {
            state.pageSize = action.payload
        },
        setButtonText: (state, action) => {
            state.buttonText = action.payload
        },
        changeResumeForm: (state, action) => {
            state.resumeForm = {
                ...action.payload,
            };
            state.quanHeGiaDinh = action.payload.quanHeGiaDinh.map(item => {
                return {
                    ...item,
                    sinhNam: new Date(item?.sinhNam),
                    id: item._id,
                }
            });
            state.ngayThangNamXuatGiaThoGioiTrongHePhai = action.payload.ngayThangNamXuatGiaThoGioiTrongHePhai.map(item => {
                return {
                    ...item,
                    id: item._id,
                }
            })
        },
        setEmptyForm: (state, action) => {
            state.resumeForm = formInitialState;
            state.quanHeGiaDinh = [];
            state.ngayThangNamXuatGiaThoGioiTrongHePhai = []
        },
        toggleOpenPdf: (state, action) => {
            state.openPdf = !state.openPdf
        }


    },
    extraReducers: (builder) => {
        builder
            .addCase(getResumeData.fulfilled, (state, action) => {
                state.loading = false;
                state.resumeData = action.payload.profiles;
                state.totalCount = action.payload.totalCount
            })
            .addCase(deleteProfileById.fulfilled, (state, action) => {
                state.resumeData = state.resumeData.filter(item => item._id !== action.payload.id)
                state.totalCount = state.totalCount - 1;
            })

    },
});
export const { setCreateNewResume, setPage, setPagesize, setButtonText, changeResumeForm, setEmptyForm, toggleOpenPdf } = resumeSlice.actions;

const resumeReducer = resumeSlice.reducer;

export default resumeReducer;
