export const HOST =
    process.env.REACT_APP_NODE_ENV === "production"
        ? process.env.REACT_APP_HOST_DEV
        : process.env.REACT_APP_HOST_PRODUCTION;


export const REGISTER = "auth/register";
export const LOGIN = "auth/login";
export const INFO_USER = "auth/me";
export const CREATE_RESUME = "profile";
export const GET_RESUME = "profile/";
export const DELETE_RESUME = "profile/";
export const UPLOAD_IMG = "images/";
export const IMPORT_EXCEL = "excel/";
