import { Avatar } from '@mui/material';
import React from 'react'
import styles from './index.module.css';
import cn from 'classnames';

const InputUpload = ({value, handleChange}) => {
  return (
    <div className={cn(styles.upload_container, 'z-20')}>
      <Avatar sx={{ width: 100, height: 100 }} src={value} />
      <input type="file" onChange={handleChange} accept='image/png, image/jpg, image/jpeg'/>
    </div>
  );
}

export default InputUpload