// Icon Imports
import {
    MdHome,
    MdPerson,
    MdLock,
} from "react-icons/md";

const routes = [
    {
        name: "Quản lý",
        layout: "/admin",
        path: "default",
        icon: <MdHome className="h-6 w-6" />,

    },

    // {
    //     name: "Tài khoản",
    //     layout: "/admin",
    //     path: "profile",
    //     icon: <MdPerson className="h-6 w-6" />,
 
    // },
    // {
    //     name: "Đăng nhập",
    //     layout: "/auth",
    //     path: "sign-in",
    //     icon: <MdLock className="h-6 w-6" />,

    // }
];
export default routes