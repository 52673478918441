import React from "react";
import { useSelector } from "react-redux";
import logo from "assets/img/dashboards/logo.png";
import { Box } from "@mui/material";
import styles from "./index.module.css";
import { Check } from "@mui/icons-material";
import cn from "classnames";

const PDFPreview = ({ pdfRef }) => {
  const { resumeForm } = useSelector((state) => state.resume);

  const gioiPham = {
    xuatGia: "XUẤT GIA",
    saDiNi: "SA DI NI",
    thucXoa: "THỨC XOA",
    tyKheoNi: "TỲ KHEO NI",
  };

  const sort = ["Cha", "Mẹ"];
  const arrangeFamily = resumeForm?.quanHeGiaDinh.slice().sort((a, b) => {
    const aIndex = sort.indexOf(a.relationship);
    const bIndex = sort.indexOf(b.relationship);

    // If both a and b are in the sort array, sort based on their index in the sort array
    if (aIndex !== -1 && bIndex !== -1) {
      return aIndex - bIndex;
    }

    // If only a is in the sort array, it should come before b
    if (aIndex !== -1) {
      return -1;
    }

    // If only b is in the sort array, it should come before a
    if (bIndex !== -1) {
      return 1;
    }

    // If neither a nor b is in the sort array, maintain their original order
    return 0;
  });

  return (
    <div
      className={cn("px-5 py-5 md:px-[80px]", styles.container)}
      ref={pdfRef}
      id="pdf-container"
    >
      <div className="flex items-center justify-between">
        <Box className="flex">
          <img
            src={logo}
            alt="logo"
            className="h-[50px] w-[50px] md:h-[80px] md:w-[80px]"
          />
          <Box className="text-center">
            <h2 className="text-sm font-medium md:text-lg">
              GIÁO HỘI PHẬT GIÁO VIỆT NAM
            </h2>
            <h3 className="text-sm font-medium md:text-lg">
              NI GIỚI HỆ PHÁI KHẤT SĨ
            </h3>
            <span>-----oOo-----</span>
          </Box>
        </Box>
        <Box className="text-center">
          <h2 className="text-sm font-medium md:text-lg">
            CỘNG HOÀ XÃ HỘI CHỦ NGHĨA VIỆT NAM
          </h2>
          <h3 className="text-sm font-medium md:text-lg">
            Độc Lập – Tự Do – Hạnh Phúc
          </h3>
          <span>-----oOo-----</span>
        </Box>
      </div>

      <div className="my-5">
        <h1 className="my-3 text-center text-2xl font-bold">SƠ YẾU LÝ LỊCH</h1>
        <h3 className="my-3 text-center">
          (NI CHÚNG TRỰC THUỘC NI GIỚI HỆ PHÁI KHẤT SĨ)
        </h3>
        <div className="section-content">
          <div className="mb-2 flex">
            <span>Pháp danh: </span>
            <p className={styles.border_dot}>{resumeForm?.phapDanh}</p>
          </div>
          <div className="mb-2 flex">
            <span>Họ và tên khai sinh: </span>
            <p className={styles.border_dot}>{resumeForm?.hoTenKhaiSinh}</p>
          </div>
          <div className="mb-2 flex">
            <span> Ngày tháng năm sinh: tại: </span>
            <p className={styles.border_dot}>{resumeForm?.ngaySinh}</p>
            <span> Nơi sinh: </span>
            <p className={styles.border_dot}>{resumeForm?.noiSinh}</p>
          </div>
          <div className="mb-2 flex">
            <span>Giấy CMND/CCCD số:</span>
            <p className={styles.border_dot}> {resumeForm?.soCMND}</p>
            <span>cấp ngày: </span>
            <p className={styles.border_dot}>{resumeForm?.ngayCapCMND}</p>{" "}
            <span>tại:</span>{" "}
            <p className={styles.border_dot}>{resumeForm?.noiCapCMND}</p>
          </div>
          <div className="mb-2 flex">
            <span>Giấy Chứng nhận Tăng Ni số:</span>
            <p className={styles.border_dot}>{resumeForm?.soChungNhanTangNi}</p>
            <span>cấp ngày:</span>
            <p className={styles.border_dot}>{resumeForm?.ngayCapCNTN}</p>
          </div>
          <div className="mb-2 flex">
            <span>Quê quán:</span>{" "}
            <p className={styles.border_dot}>{resumeForm?.queQuan}</p>
          </div>
          <div className="mb-2 flex">
            <span>Hộ khẩu thường trú:</span>{" "}
            <p className={styles.border_dot}>{resumeForm?.hoKhauThuongTru}</p>
          </div>
          <div className="mb-2 flex">
            <span> Hiện ở Tịnh xá (Ghi rõ địa chỉ): </span>
            <p className={styles.border_dot}>{resumeForm?.hienO}</p>
          </div>
          <div className="mb-2 flex">
            <span> Và tạm trú tại: </span>
            <p className={styles.border_dot}>{resumeForm?.tamTruTai}</p>
          </div>
          <div className="mb-2 flex">
            <span>
              Ngày tháng năm xuất gia vào Tịnh xá (ghi rõ địa chỉ TX):{" "}
            </span>
            <p className={styles.border_dot}>
              {resumeForm?.ngayXuatGiaVaoTinhXa}
            </p>
          </div>
          <div className="mb-2 flex">
            <span>
              Ngày tháng năm và nơi xuống tóc xuất gia (ghi rõ địa chỉ TX):{" "}
            </span>
            <p className={styles.border_dot}>
              {resumeForm?.ngayXuatGiaXuongToc}
            </p>
          </div>
          <div className="mb-2 flex">
            <span>Bổn Sư và địa chỉ Bổn Sư:</span>
            <p className={styles.border_dot}>{resumeForm?.bonSu}</p>
          </div>
          <div className="mb-2 flex gap-5">
            Trình độ:
            <div className="flex-1">
              <div className="flex">
                <span>+ Văn hoá:</span>{" "}
                <p className={styles.border_dot}>
                  {resumeForm?.trinhDo?.vanHoa}
                </p>
              </div>
              <div className="flex">
                <span>+ Phật học:</span>{" "}
                <p className={styles.border_dot}>
                  {resumeForm?.trinhDo?.phatHoc}
                </p>
              </div>
              <div className="flex">
                {" "}
                <span>+ Ngoại ngữ:</span>{" "}
                <p className={styles.border_dot}>
                  {resumeForm?.trinhDo?.ngoaiNgu}
                </p>
              </div>
              <div className="flex">
                <span> + Nghiệp vụ chuyên môn: </span>
                <p className={styles.border_dot}>
                  {resumeForm?.trinhDo?.ngheNghiepChuyenMon}
                </p>
              </div>
            </div>
          </div>

          <h3 className="text-md my-1 text-center font-bold">
            Ngày Tháng Năm Xuất Gia Thọ Giới Trong Hệ Phái
          </h3>

          {resumeForm?.ngayThangNamXuatGiaThoGioiTrongHePhai[0] && (
            <div>
              <h3 className="my-1">
                <strong>
                  <Check />
                  Chính thức:
                </strong>{" "}
                (xin kê khai đúng theo năm thọ giới trong Hệ phái để sắp xếp
                giới phẩm)
              </h3>
              {Object.entries(
                resumeForm?.ngayThangNamXuatGiaThoGioiTrongHePhai[0]
              ).map(([key, value]) => (
                <div key={key} className="my-1 flex">
                  {gioiPham[key] && (
                    <>
                      <p className="font-bold">{gioiPham[key]}: </p>
                      <p className={styles.border_dot}>{value}</p>
                    </>
                  )}
                </div>
              ))}
            </div>
          )}

          {resumeForm?.ngayThangNamXuatGiaThoGioiTrongHePhai[1] && (
            <div>
              <h3 className="my-1">
                <strong>
                  <Check />
                  Kê khai trên giấy tờ
                </strong>
              </h3>
              {Object.entries(
                resumeForm?.ngayThangNamXuatGiaThoGioiTrongHePhai[1]
              ).map(([key, value]) => (
                <div key={key} className="my-1 flex">
                  {gioiPham[key] && (
                    <>
                      <p className="font-bold">{gioiPham[key]}: </p>
                      <p className={styles.border_dot}>{value}</p>
                    </>
                  )}
                </div>
              ))}
            </div>
          )}

          {resumeForm?.ngayThangNamXuatGiaThoGioiTrongHePhai[2] && (
            <div>
              <h3 className="my-1 text-center">
                <strong>
                  <Check />
                  Ngày tháng năm thọ giới đàn do BAN TRỊ SỰ TỈNH, THÀNH tổ chức
                </strong>{" "}
              </h3>
              {Object.entries(
                resumeForm?.ngayThangNamXuatGiaThoGioiTrongHePhai[2]
              ).map(([key, value]) => (
                <div key={key} className="my-1 flex">
                  {gioiPham[key] && (
                    <>
                      <p className="font-bold">{gioiPham[key]}: </p>
                      <p className={styles.border_dot}>{value}</p>
                    </>
                  )}
                </div>
              ))}
            </div>
          )}

          <div >
            <h2 className="text-center font-bold">TIỂU SỬ BẢN THÂN</h2>
            <p className="my-2 text-center">
              (Từ 15 tuổi đến nay làm gì, ở đâu, ghi rõ thời gian từ năm nào đến
              năm nào)
            </p>
            <p className={cn(styles.border_dot, "my-2")}>
              {resumeForm?.tieuSuBanThan}
            </p>
          </div>
          <div className="mb-2 flex">
            <span>Tham gia các sinh hoạt xã hội:</span>
            <p className={styles.border_dot}>
              {resumeForm?.thamGiaSinhHoatXaHoi}
            </p>
          </div>

          <div className="mb-2 flex">
            <span> Tham gia phong trào, đoàn thể, đảng phái:</span>
            <p className={styles.border_dot}>{resumeForm?.thamGiaPhongTrao}</p>
          </div>
          <div className="mb-2 flex">
            <span>Khen thưởng, kỷ luật (nếu có): </span>
            <p className={styles.border_dot}>{resumeForm?.khenThuongKyLuat}</p>
          </div>
        </div>
      </div>

      <div>
        <h2 className="text-center font-bold">QUAN HỆ GIA ĐÌNH</h2>
        <div className="section-content">
          {arrangeFamily.map((item) => (
            <div key={item?._id} className="my-1">
              <div className="flex">
                <strong>Họ tên {item?.relationship}:</strong>
                <p className={styles.border_dot}>{item?.hoTen}</p>
                <strong>Sinh năm: </strong>
                <p className={styles.border_dot}>
                  {new Date(item?.sinhNam).toLocaleDateString()}{" "}
                </p>
              </div>
              <div className="flex">
                <strong>Nghề nghiệp:</strong>
                <p className={styles.border_dot}>{item?.ngheNghiep}</p>
                <strong>Địa chỉ:</strong>
                <p className={styles.border_dot}>{item?.diaChi}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="declaration">
        <p className="indent-5">
          Tôi xin cam đoan bản sơ yếu lý lịch này đúng sự thật và xin chịu trách
          nhiệm về lời khai trên đây.
        </p>
      </div>
      <div className="my-2 flex justify-between">
        <div>
          <p>Ghi chú:</p>
          <p>Gát y:</p>
          <p>Chuyển phái:</p>
          <p>Viên tịch:</p>
          <p>Hoàn tục:</p>
        </div>
        <div className="text-center">
          <p>……… ngày … tháng … năm ……</p>
          <p>Người khai ký tên</p>
        </div>
      </div>
    </div>
  );
};

export default PDFPreview;
