import moment from "moment";

export const checkEmail = (email) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

export const regexEmail = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{1,3})+$/;

export const phoneRegExp = /^\(\d{3}\)\s\d{3}\s-\s\d{4}/g;

export const passwordRegExp = /^(?=.*?[A-Z])(?=.*?[0-9])/;

export const urlRegex =
  /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;

export const formatPrice = (number) => {
  if (!number) return 0;
  return new Intl.NumberFormat("en-US", { maximumSignificantDigits: 3 }).format(
    number
  );
};

export const convertNameFromUserData = (user) => {
  if (!user) return "";
  return `${user?.firstName || ""} ${user?.lastName || ""}`;
};

export const getExtFile = (stringPath) => {
  return /[.]/.exec(stringPath) ? /[^.]+$/.exec(stringPath) : "";
};

export const getFileName = (stringPath) => {
  return stringPath.replace(/^.*[\\/]/, "");
};

export const removeObjectKeyNull = (ObjectData) => {
  const object = {};
  for (const [key, value] of Object.entries(ObjectData)) {
    if (value) {
      object[key] = value;
    }
  }

  return object;
};

// result 1 => date > targetDate, 0 date = targetDate, -1, date < targetDate
export const equalDate = (targetDate, date) => {
  const convertTargetDate = new Date(targetDate).getTime();
  const convertDate = new Date(date).getTime();
  const oneDate = 86400000; // 1day = 86400000 milisecon
  const eq = convertDate - convertTargetDate;
  return eq === 0 ? 0 : eq / oneDate;
};

/**
 * compare date for function sort by result
 *
 * @param {string} currentDate // date item current
 * @param {string} preDate  // date item pre
 * @return 0 or 1 => not swap item, -1 => swap item
 */
export const compareDate = (currentDate, preDate) => {
  // result= -1 => swap current date vs pre date item message 0 or 1 => not change
  let result = 0;
  if (preDate && currentDate) {
    // equal pre date create Date  vs current date create Date
    const eq = equalDate(currentDate, preDate);
    result = eq >= 0 ? 1 : -1;
  }
  // current date = null => not change
  if (!currentDate && preDate) {
    result = 1;
  }
  // pre date = null => swap item
  if (currentDate && !preDate) {
    result = -1;
  }
  return result;
};

export const formatDate = (date = new Date(), type = "DD/MM/YYYY") => {
  return moment(date).format(type);
};

// remove vietnamese special character
export const removeSpecialCharacter = (value) => {
  return value
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/đ/g, "d")
    .replace(/Đ/g, "d")
    .toLowerCase();
};
// convert number to roman number
export function convertToRoman(num) {
  var roman = {
    M: 1000,
    CM: 900,
    D: 500,
    CD: 400,
    C: 100,
    XC: 90,
    L: 50,
    XL: 40,
    X: 10,
    IX: 9,
    V: 5,
    IV: 4,
    I: 1,
  };
  var result = "";
  for (var key in roman) {
    if (num == roman[key]) {
      return (result += key);
    }
    var check = num > roman[key];
    if (check) {
      result = result + key.repeat(parseInt(num / roman[key]));
      num = num % roman[key];
    }
  }
  return result;
}
// use in calendar
export const formatDateString = (date) => {
  return date.slice(0, 16);
};
export const checkValidTime = (start, end, dateArray) => {
  const newStartDate = new Date(start);
  const newEndDate = new Date(end);
  for (let i = 0; i < dateArray.length; i++) {
    const existingStartDate = new Date(dateArray[i].start);
    const existingEndDate = new Date(dateArray[i].end);

    // Check if the new range overlaps with any existing range
    if (newStartDate < existingEndDate && newEndDate > existingStartDate) {
      return false; // Overlapping ranges, not valid
    }
  }

  return true; // No overlapping ranges, valid
};